<template>
  <div class="about">
    <h1></h1>
  </div>
</template>

<script>
export default {
  mounted() {
    // alert(this.$route.params.id);
  }
};
</script>
