var api_url = '';
var base_url = '';

switch (process.env.NODE_ENV) {
  case 'development':
    api_url = 'https://localhost:5001/api';
    // api_url = 'https://ninweb17.nina.no/BestandsovervakningApi/api';
    // base_url = "";
    break;
  case 'production':
    // api_url = 'https://ninweb17.nina.no/BestandsovervakningApi/api';
    api_url = 'https://bestandapi.nina.no/BestandsovervakningApi/api';
    base_url = '/Bestandsovervakning/';
    break;
}

export const BESTANDSOVERVAKNING_CONFIG = {
  API_URL: api_url,
  BASE_URL: base_url
};
