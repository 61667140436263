<template>
  <!-- <Layout> -->
  <!-- <img src="@assets/images/logo.png" alt="Logo" /> -->
  <b-container fluid style="background-color: #D5D5E3;  padding-left: 0px; padding-right: 0px;">
    <div class="menu-content">
      <div style="padding-left: 15px; padding-right: 15px;">
        <div class="accordion" role="tablist" v-for="(county, index) in countiesWithWatercourses" :key="index">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab" style="color: red">
              <b-button variant="light" block v-b-toggle="'accordion-' + index">{{ county.countyName }}</b-button>
            </b-card-header>
            <b-collapse :id="getCountyId(index)" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <ul>
                  <li v-for="(watercourse, index) in county.watercourses" :key="index">
                    <a href="#" v-on:click="
          getLastReport(watercourse.code),
          getSelectedWatercourse(watercourse.code),
          getWatercourseForMap(watercourse.code),
          getReportList(watercourse.code)
          ">{{ watercourse.code }} -
                      {{ titleCase(watercourse.name) }}</a>
                  </li>
                </ul>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div align="center" style="padding:10px; ">
          <div style="padding:5px">
            <a href="https://www.nina.no"><img :src="images.logo_nina" alt="NINA" width="120" /></a>
          </div>
          <div style="padding:5px">
            <a href="https://www.norceresearch.no"><img :src="images.logo_norce" alt="NORCE" width="120" /></a>
          </div>
          <div style="padding:5px">
            <a href="https://www.facebook.com/Naturovervaking/"><img :src="images.logo_sno"
                alt="Skandinavisk Naturovervåkning AS" width="120" /></a>
          </div>
          <div style="padding:5px">
            <a href="https://www.radgivende-biologer.no/"><img :src="images.logo_rb" alt="Rådgivende biologer AS"
                width="120" /></a>
          </div>
          <div style="padding:5px">
            <a href="https://www.vetinst.no/"><img :src="images.logo_vi" alt="Veterinærinstituttet" width="120" /></a>
          </div>
          <div style="padding:5px">
            <a href="https://ninord.no/"><img :src="images.logo_ninord" alt="Naturtjenester i Nord" width="120" /></a>
          </div>
        </div>
      </div>
    </div>

    <div class="main-content">
      <div class>
        <BTabs v-model="tabIndex" content-class="mt-3" name="tabber">
          <b-tab to="/kart" title="Kart" active @click="[mapReadyMainMap()]">
            <!-- mapReadyMainMap()-->
            <div class="row">
              <div class="col-md-4 mb-4">Søk etter vassdrag:</div>
              <div class="col-md-4 mb-4">
                <b-form-input v-model="searchWatercourse" list="my-list-id"></b-form-input>
                <datalist id="my-list-id">
                  <!-- <option>Manual Option</option> -->
                  <option v-for="(watercourse, index) in watercoursesForMap" :key="watercourse.code + index">
                    {{ watercourse.code }} - {{ titleCase(watercourse.name) }}
                  </option>
                </datalist>
              </div>
              <div class="col-md-4 mb-4">
                <b-button @click="searchForWatercourse">Gå til vassdrag</b-button>
              </div>
            </div>

            <div class="container border">
              <l-map ref="myMap" :zoom="zoom" :center="center" style="height:800px" @ready="doSomethingOnReady">
                <l-control-layers position="topright"></l-control-layers>
                <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.key" :name="tileProvider.name"
                  :visible="tileProvider.visible" :url="tileProvider.url" :attribution="tileProvider.attribution"
                  layer-type="base" />
                <l-wms-tile-layer v-for="layer in layers" :key="layer.name" :base-url="baseUrl" :layers="layer.layers"
                  :visible="layer.visible" :name="layer.name" :version="layer.version" layer-type="base" />

                <l-circle-marker v-for="w in watercoursesForMap" :key="w.id"
                  :lat-lng="{ lat: w.latitude, lng: w.longitude }" @click="
          getLastReport(w.code),
          getSelectedWatercourse(w.code),
          getWatercourseForMap(w.code),
          getReportList(w.code)
          ">
                  />
                  <l-tooltip :content="w.code + ' - ' + w.name" />></l-circle-marker>
              </l-map>
            </div>
          </b-tab>
          <b-tab title="Vassdrag" @click="[mapReady()]">
            <div v-if="selectedReport" class="container p-4">
              <div>
                <h3>
                  {{ selectedWatercourse.code }} -
                  {{ titleCase(selectedWatercourse.name) }}
                </h3>
              </div>

              <div>
                <select class="form-control" @change="getSelectedReport($event)">
                  <!-- <option selected="selected" value="null" disabled>-</option> -->
                  <option v-for="(report, index) in reportList" :value="report.id" :key="report.code + index">{{
          yearOnly(report.date) }} - {{ report.method }} -{{
          report.excecutiveInstitution
        }}
                  </option>
                </select>
              </div>

              <div v-if="selectedReport" class="container p-3">
                <h4>
                  Generelt om elva og tellingen - {{ selectedReport.year }}
                </h4>

                <div>
                  <div class="row pb-2">
                    <div class="col bold">Undersøkelsestidspunkt:</div>
                    <div class="w-50 col-auto">
                      <span> {{ formattedDate(selectedReport.date) }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Utførende institusjon:</div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.excecutiveInstitution }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Metode:</div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.method }}</span>
                    </div>
                  </div>
                  <div v-if="selectedReport">
                    <div class="row pb-2">
                      <div class="col bold">Anadrom strekning:</div>
                      <div class="w-50 col-auto">
                        <span> {{ selectedWatercourse.anadromLength }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">
                      Innsjøer i anadrom del av vassdraget:
                    </div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.lakeAnadrom }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">
                      Prosent av anadrom strekning undersøkt:
                    </div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.percentAnadrom }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Elvestrekninger som inngår:</div>
                    <div class="w-50 col-auto">
                      <span v-if="selectedReport.riverStretch">
                        {{ selectedReport.riverStretch }}
                      </span>
                      <span v-else> Ikke utført</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Vanntemperatur (C):</div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.waterTemperature }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Vannføring (m3/s):</div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.waterFlow }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Effektiv sikt (meter):</div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.visibility }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Kort feltrapport:</div>
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <span>{{ selectedReport.fieldReportText }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Slutt generelt om tellingen -->
              <!-- Start kvalitetsparametre -->
              <div class="container p-3">
                <h4>Kvalitetsparametre</h4>
                <div class="row pb-2">
                  <div class="col bold">
                    Sikt og observasjonsforhold:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{ selectedReport.observationConditionsName }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Utfordringer med å identifisere fisk som følge av store
                    vannvolum (dype høler/loner) eller store fisketettheter:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{ selectedReport.challengesName }} </span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Kvalitetsvurdering av hvor stor andel av gytebestanden som
                    trolig ble observert (i prosent) Laks:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{
          selectedReport.qP_SalmonQualityAssessmentPercent
        }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Kvalitetsvurdering av hvor stor andel av gytebestanden som
                    trolig ble observert (i prosent) SØ:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{
            selectedReport.qP_BrownTroutQualityAssessmentPercent
          }}
                    </span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Utførelse i forhold til gytetidspunkt Laks:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{ selectedReport.salmonExecutionTimeName }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Utførelse i forhold til gytetidspunkt SØ:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{
          selectedReport.brownTroutExecutionTimeName
        }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Utførelse i forhold til gytetidspunkt Pukkelaks:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{
            selectedReport.pinkSalmonExecutionTimeName
          }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Laks - Total kvalitetsvurdering for hvor godt egnet dataene
                    er for å beskrive bestandsstørrelse:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{
            selectedReport.salmonQualityAssessmentName
          }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Sjøørret - Total kvalitetsvurdering for hvor godt egnet
                    dataene er for å beskrive bestandsstørrelse:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{ selectedReport.brownTroutQualityAssessmentName }}
                    </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col bold">
                    Begrunnelse for kvalitetsvurdering:
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-auto">
                    <span>{{ selectedReport.qP_QualityAssessmentRationale }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Slutt kvalitetsparametre -->
              <!-- Start telledata -->
              <div class="container p-3">
                <h4>Telledata</h4>

                <table class="table table-hover table-striped">
                  <thead>
                    <tr class="bg-default">
                      <th colspan="5">LAKS</th>
                      <th colspan="6">SJØØRRET</th>
                      <th colspan="2">SJØRØYE</th>
                      <th colspan="2">PUKKELLAKS</th>
                    </tr>
                    <tr class="bg-default" style="font-size:12px">
                      <th scope="col"></th>
                      <th scope="col">Små (1-3 kg)</th>
                      <th scope="col">Mellom (3-7 kg)</th>
                      <th scope="col">Store (> 7 kg</th>
                      <th scope="col">Totalt</th>
                      <th scope="col">Umodne</th>
                      <th scope="col">&lt; 1 kg</th>
                      <th scope="col">1-3 kg</th>
                      <th scope="col">3-5 kg</th>
                      <th scope="col">&gt;5 kg</th>
                      <th scope="col">Totalt</th>
                      <th scope="col">&lt; 0,5 kg</th>
                      <th scope="col">&gt; 0,5 kg</th>
                      <th scope="col">Ikke gytt</th>
                      <th scope="col">Utgytt</th>
                    </tr>
                    <tr>
                      <td>Villfisk</td>
                      <td>
                        <!-- {{ selectedReport.cD_WildSalmonSmallFemale }} |
                    {{ selectedReport.cD_WildSalmonSmallMale }} |
                    {{ selectedReport.cD_WildSalmonSmallUnknown }} | -->
                        {{ wildSalmonSmallAll }}
                      </td>
                      <td>
                        <!-- {{ selectedReport.cD_WildSalmonMediumFemale }} |
                    {{ selectedReport.cD_WildSalmonMediumMale }} |
                    {{ selectedReport.cD_WildSalmonMediumUnknown }} | -->
                        {{ wildSalmonMediumAll }}
                      </td>
                      <td>
                        <!-- {{ selectedReport.cD_WildSalmonLargeFemale }} |
                    {{ selectedReport.cD_WildSalmonLargeMale }} |
                    {{ selectedReport.cD_WildSalmonLargeUnknown }} | -->
                        {{ wildSalmonLargeAll }}
                      </td>
                      <td>{{ sumWildSalmon }}</td>
                      <td>{{ selectedReport.cD_BrownTroutImmature }}</td>
                      <td>{{ selectedReport.cD_BrownTrout_0_1 }}</td>
                      <td>{{ selectedReport.cD_BrownTrout_1_3 }}</td>
                      <td>{{ selectedReport.cD_BrownTrout_3_5 }}</td>
                      <td>{{ selectedReport.cD_BrownTrout_5 }}</td>
                      <td>{{ sumBrownTrout }}</td>
                      <td>{{ selectedReport.cD_ArcticChar_0_05 }}</td>
                      <td>{{ selectedReport.cD_ArcticChar_05 }}</td>
                      <td>{{ selectedReport.cD_PinkSalmonPreSpawn }}</td>
                      <td>{{ selectedReport.cD_PinkSalmonPostSpawn }}</td>
                    </tr>
                    <tr>
                      <td>Oppdrett</td>
                      <td>{{ selectedReport.cD_CultivatedSalmonSmall }}</td>
                      <td>
                        <!-- {{ selectedReport.cD_CultivatedSalmonMediumFemale }} |
                    {{ selectedReport.cD_CultivatedSalmonMediumMale }} |
                    {{ selectedReport.cD_CultivatedSalmonMediumUnknown }} | -->
                        {{ cultivatedSalmonMediumAll }}
                      </td>
                      <td>
                        <!-- {{ selectedReport.cD_CultivatedSalmonLargeFemale }} |
                    {{ selectedReport.cD_CultivatedSalmonLargeMale }} |
                    {{ selectedReport.cD_CultivatedSalmonLargeUnknown }} | -->
                        {{ cultivatedSalmonLargeAll }}
                      </td>
                      <td>
                        {{ sumCultivated }}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colspan="15"></td>
                    </tr>
                    <tr>
                      <td colspan="4">Observert kultivert laks (%)</td>
                      <td colspan="11">
                        {{ selectedReport.cD_CultivatedSalmonPercent }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4">Observert kultivert laks (n)</td>
                      <td colspan="11">
                        {{ selectedReport.cD_CultivatedSalmon }}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <!-- Slutt telledata -->
            </div>
            <div>
              <img 
                v-if="!imageErrors[0]" 
                :src="getSalmonMainImageUrl()" 
                class="stats-image" 
                @error="() => imageErrors[0] = true" 
                @click="openModal(getSalmonMainImageUrl())"
              />
              <img 
                v-if="!imageErrors[1]" 
                :src="getSalmonMainImageUrl(true)" 
                class="stats-image" 
                @error="() => imageErrors[1] = true" 
                @click="openModal(getSalmonMainImageUrl(true))"
              />
              <img 
                v-if="!imageErrors[2]" 
                :src="getSalmonVariantImageUrl()" 
                class="stats-image" 
                @error="() => imageErrors[2] = true" 
                @click="openModal(getSalmonVariantImageUrl())"
              />

              <img 
                v-if="!imageErrors[3]" 
                :src="getTroutMainImageUrl()" 
                class="stats-image" 
                @error="() => imageErrors[3] = true" 
                @click="openModal(getTroutMainImageUrl())"
              />
              <img 
                v-if="!imageErrors[4]" 
                :src="getTroutMainImageUrl(true)" 
                class="stats-image" 
                @error="() => imageErrors[4] = true" 
                @click="openModal(getTroutMainImageUrl(true))"
              />
              <img 
                v-if="!imageErrors[5]" 
                :src="getTroutVariantImageUrl()" 
                class="stats-image" 
                @error="() => imageErrors[5] = true" 
                @click="openModal(getTroutVariantImageUrl())"
              />

              <!-- Modal for displaying full-size images -->
              <b-modal id="image-modal" v-model="modalVisible" title=" " hide-footer>
                <img :src="currentImageUrl" class="modal-fullsize-image">
              </b-modal>
            </div>

            <div class="container p-1">
              <div id="mapid" ref="mapElement" style="height:600px"></div>
            </div>
          </b-tab>

          <b-tab to="/metodikk" title="Metodikk">
            <h3>1 Innledning</h3>
            <p>
              I Norge forvaltes sjøvandrende laksefisk på bestandsnivå.
              Estimering av gytebestandsstørrelse er en sentral parameter for
              norsk lakseforvaltning, og danner grunnlaget for årlige
              vurderinger omkring høstingspotensial og vernebehov. Vitenskapelig
              råd for lakseforvaltning gjennomfører en årlig gjennomgang av
              vassdragene og gir råd om beskatning i de ulike vassdrag til
              Miljødirektoratet. Antallet av laks som hvert år kommer inn til
              kysten av Norge og opp i våre vassdrag, er mer enn halvert siden
              1980-tallet. Antallet laks som gyter i elvene er likevel flere nå
              enn på 1980-talllet, selv om det høstbare overskuddet er redusert.
              At antallet gytefisk har økt selv om færre laks kommer opp i våre
              elver, beror på at innskrenkinger i fisketider og reduksjon av
              fiske i elv og sjø har kompensert for tilbakegangen (Anonym 2019).
              Bestandssituasjonen hos sjøørret har også vist en negativ trend
              over store deler av landet. Påvirkninger fra akvakulturnæringen
              står for de største negative påvirkningen på sjøørret bestandene,
              men også faktorer som blant annet landbruk, vannkraft og
              urbanisering fører i stor grad til reduksjon i leveområder og
              egnet habitat for sjøørretbestandene (Anonym 2019b. Bergan &amp;
              Solem 2018). Data fra bestandsovervåkning av laks og sjøørret i
              elver bidrar i stor grad bidra til kunnskap om norske laks- og
              sjøørretbestander, og er vesentlig for å kunne opprettholde en
              kunnskapsbasert forvaltning av de ulike bestandene.
            </p>
            <p>
              Estimat av gytebestandsstørrelser foregår på flere måter,
              deriblant drivtelling, lysfiske og videoovervåking.
            </p>
            <h3>2 Metode</h3>
            <p>
              I all hovedsak gjennomføres overvåkingen av gytebestandstørrelser
              av laks og sjøørret ved drivtellinger i de ulike vassdragene. I
              mindre vassdrag der drivtelling ikke vil gi gode resultater
              gjennomføres det tellinger ved hjelp av lys på kveldstid
              (lysfiske). I noen vassdrag gjennomføres det også
              videoregistreringer eller tellinger i trapper. Alle data fra de
              ulike tellemetodene er implementert i de årlige rapportene fra
              prosjektet.
            </p>
            <p>
              I forbindelse med de praktiske undersøkelsene vil hver elv deles
              inn i hensiktsmessige soner. Disse sonene er satt ut fra den
              enkelte institusjons behov for oppløsning på sine data, og for å
              kunne visualisere fordelingen av fisk i elvestrengen. I denne
              rapporten er det imidlertid ikke gitt en fullstendig oversikt over
              alle soner som inngår i de ulike vassdragene. Det er heller lagt
              vekt på å visualisere hvilke strekninger som er undersøkt i hvert
              vassdrag. I de ulike institusjoners arkiv finnes imidlertid finnes
              en mer detaljert oversikt, der vassdragene er delt opp i soner.
              Det er dermed mulig å beskrive romlig fordeling av gytefisk og
              dermed rogndeponering i ulike deler av elvestrengen, men dette er
              ikke en del av oppdraget gitt av Miljødirektoratet.
            </p>
            <p>
              All fisk som observeres i forbindelse med bestandsovervåkingen
              klassifiseres etter størrelse i tråd med norsk standard (Anonym
              2015). For laks benyttes kategoriene smålaks (&lt; 3 kg),
              mellomlaks (3-7 kg) og storlaks (> 7 kg). Disse
              størrelseskategoriene samsvarer som oftest med sjøalder hos laks
              (1, 2 og 3 sjøvintre). Sjøørret deles i gruppene &lt; 1 kg
              (umodne/modne), 1-3 kg, 3-5 kg og > 5 kg. Sjørøye deles inn i
              størrelseskategoriene mindre enn eller større enn 0,5 kg. Det
              kreves erfaring med undervannsobservasjoner for presise
              registreringer av art, kjønn og størrelse av fisk som i stor grad
              kan opptre i mindre eller større grupper. Under feltarbeid er det
              derfor avgjørende at det blir benyttet personell fra fagmiljøer
              som har tilstrekkelig erfaring med fisketellinger i elver, for å
              kunne gi et best mulig bestandsestimat.
            </p>

            <h4>2.1 Drivtellinger</h4>
            <p>
              Drivtelling er en mye brukt og viktig metode for å fremskaffe
              kunnskap om bestandsstørrelse for laksefisk i elver. Presisjon på
              gytefisktellinger vil variere ut fra observasjonsforhold,
              mannskapets erfaring (Orell mfl. 2011) og vassdragets utforming
              (Orell &amp; Erkinaro 2007). En absolutt forutsetning for
              undervannsobservasjoner av fisk er at siktforholdene er
              tilfredsstillende (Gardiner 1984). Erfaringer med telling av
              gytefisk i elver der antall oppvandrende fisk er kjent fra
              fiskefeller eller videotelling, tilsier at en normalt ser 80 %
              eller mer under gode forhold (Skoglund mfl. 2014). Generelt antas
              det imidlertid at en vil få en større underestimering av
              bestandene i større vassdrag med mange dype områder og stort
              vannvolum (Skoglund mfl. 2014). De fleste gytefisktellinger i
              Norge har blitt gjennomført i mindre og mellomstore vassdrag.
              Imidlertid finnes det noen unntak som Saltdalselva, Beiarelva,
              Ranaelva, Røssåga (Kanstad-Hanssen &amp; Lamberg 2013), Vefsna
              (Holthe mfl. 2019a), Gaula, Orkla (Lamberg 2018), Surna (Johnsen
              mfl. 2011) og Driva (Bremset mfl. 2012). Det er imidlertid ikke
              kjent hvor stor andel av gytefisken som har blitt observert i
              disse store vassdragene.
            </p>
            <h5>2.1.1 Beskrivelse av metode</h5>
            <p>
              Drivtellinger i elver med det formål å kartlegge gytebestander vil
              utføres ved at tellerne svømmer/driver nedover elva iført tørr-
              eller våtdrakter og snorkelutstyr (Vollset mfl. 2014). Stans i
              tellingene gjøres ved naturlige stoppunkter, som grunne
              strømnakker eller stilleflytende partier der det ikke står fisk.
              Tellerne får da muligheten til å notere observasjoner av fisk og
              diskutere observasjoner seg imellom. For å ha tilfredsstillende
              oversikt må tellerne holde blikket så langt fram som sikten
              tillater det, og pendle med hode fra side til side for å søke over
              en så stor sektor som mulig. For å unngå dobbeltregistreringer er
              det viktig å kun telle fisk som passeres, og ikke fisk som svømmer
              foran telleren nedover elva. I større vassdrag er det alltid flere
              tellere i elva samtidig, og det er derfor viktig at drivtellerne i
              størst mulig grad svømmer på linje i en mest mulig rett vinkel på
              elvestrømmen. Ved stopp kan nærliggende tellere samkjøre data for
              å forsøke å sikre at det ikke er gjennomført dobbelttellinger.
              Følgebåt er også benyttet i større laksevassdrag som Vefsna
              (Holthe mfl. 2019a), Driva (Bremset mfl. 2012), Surna (Ugedal mfl.
              2014) og Eira (Jensen mfl. 2014). Erfaringene fra disse
              vassdragene er at følgebåt med lokalkjent mannskap har en
              betydelig sikkerhetsmessig gevinst (Bremset mfl. 2012), samt at
              man også kan få høy oppløselighet på data ved at observasjonene
              stedfestes med GPS. Høy oppløselighet på data er en fordel dersom
              man ønsker å analysere romlig fordeling av eggdeponering eller
              fordeling av gytefisk i elvene (Ugedal mfl. 2014, Holthe mfl.
              2019a).
            </p>
            <h5>2.1.2 Presisjon på estimering av antall</h5>
            <p>
              Presisjonen for estimering av mengde laks ved hjelp av drivtelling
              er undersøkt i flere merkeforsøk. I slike forsøk merkes et kjent
              antall fisk med ulike merker og gjensynsraten bestemmes under
              drivtellingen. Mulige feilkilder ved merke/gjensynforsøk er at
              fisken blir mer synlig på grunn av merket, at den står delvis i
              skjul slik at merket ikke er synlig, at merket er for lite til å
              være godt synlig eller at fisken er merket kun på én side.
              Observasjonssannsynligheten kan dermed både over- og
              underestimeres ved merke/gjensynforsøk.
            </p>
            <p>
              For å gjennomføre bestandsestimat ble drivtelling med tre dykkere
              benyttet i Altaelva i perioden 2009-2011 ved å merke laks under
              høstfiske med godt synlige ytre merker (Peterson disc tags og
              radiomerker) (Ugedal mfl. 2010, 2011, 2015). Resultatene fra disse
              undersøkelsene viste at mindre enn en tredjedel av den merkede
              laksen ble observert under drivtelling og antallet laks som ble
              observert i det samme området varierte betydelig mellom påfølgende
              drivtellinger. Den lave gjensynsraten kan skyldes dårlig sikt og
              at Altaelva er en stor og til dels en uoversiktlig elv, og at
              antallet drivtellere var for lavt i henhold til det som anbefales
              i Norsk Standard for elver at denne størrelsen.
            </p>
            <p>
              Forsøk med merking-gjensyn i en liten og klar sideelv i
              Tanavassdraget (Orell mfl. 2011) tyder på at presisjonen på
              drivtellinger kan være betydelig større enn det som ble funnet i
              Altaelva. I denne sideelva ble 65-72 % og 81-82 % av radiomerket
              og videotelt laks observert av henholdsvis uerfarent og erfarent
              personell. Presisjonen varierte også mellom elveavsnitt. I kulper
              var nøyaktigheten 75-100 %, mens 43-82 % ble observert i
              strykområdene. Orell mfl. (2011) konkluderte med at drivtellinger
              er en god metode for å telle laks i mindre elver med gode
              siktforhold.
            </p>
            <p>
              Presisjon på drivtelling har også blitt undersøkt i Lakselva i
              Finnmark i 2014 og 2016, der henholdsvis 71 og 43 % av radiomerket
              laks ble observert (Havn mfl. 2014; Uglem mfl. 2017). Fiskene i
              Lakselva var merket med synlige merker på begge sider, og
              vannføringen var lavere og siktforholdene var bedre i 2014 enn i
              2016. Registreringene ble utført av to drivtellere og presisjonen
              ville trolig vært bedre dersom flere drivtellere hadde blitt
              brukt. Det har også blitt utført en metodetest i Skibotnelva i
              Troms der 22 av 26 (85 %) radiomerkede sjøørret og sjørøye ble
              observert under drivtelling to og tre dager etter merking (Kanstad
              Hanssen 2010). Nøyaktigheten ved drivtelling er videre undersøkt i
              Røssåga i 2009 ved merking av 30 fisk med «Floy-merker» på den ene
              siden av fisken (Lamberg mfl. 2010). Her ble 13 (43 %) av de
              merkede fiskene observert av seks drivtellere. En medvirkende
              årsak til den lave gjensynsraten i både Skibotnelva og Røssåga
              oppgis å være at fiskene bare var merket på én side (Lamberg mfl.
              2010). Merkinggjensynvalidering av drivtelling tyder dermed på at
              andelen av fisk som observeres kan variere, samt at nøyaktigheten
              er bedre i mindre og klare elver enn i større elver.
            </p>
            <p>
              En annen metode for å evaluere nøyaktighet er å sammenligne
              drivtellingstall med notkast i samme høl. På denne måten unngår
              man problematikk knyttet til oppdagelsessjanse av merker. Et slikt
              studie er blitt gjennomført i en rekke vassdrag på Vestlandet
              (Mahlum mfl. 2019). Dette studiet hadde fokus på å validere i hvor
              stor grad dykkere klarer å identifisere rømt oppdrettslaks, noe
              som viste seg å ha en relativ god presisjon. I tillegg viser også
              studiet at det er meget god sammenheng mellom antall fisk
              observert i hølen og antall laks fanget i notfiske med
              strandnot/garn og garn i samme høl (Figur 1). Studiet ble
              gjennomført av erfarne dykkere fra NORCE, mens notfiske og
              skjellprøve takning ble gjennomført av Havforskningsinstituttet
              (HI) i relativt klare små og mellomstore elver.
            </p>
            <p>
              Drivtellinger er mye brukt for å estimere bestandsstørrelse i
              elver i andre land. I New Zealand er for eksempel
              oppdagelsessannsynligheten for storvokst ørret vist å variere
              mellom 21 og 77 % i elver med klart vann (Palmer &amp; Graybill
              1986; Barker 1988; Young &amp; Hayes 2001). I to NINA Rapport 1849
              12 kanadiske vassdrag fant Northcote &amp; Wilkie (1963) et godt
              samsvar mellom resultatene fra visuell fisketelling og påfølgende
              bruk av rotenon. Tilsvarende fant Dibble (1991) i et vassdrag i
              Arkansas i USA en klar sammenheng mellom relativ forekomst av
              fiskearter under fisketellinger og det som senere ble funnet under
              en påfølgende rotenonbehandling.
            </p>
            <h4>2.2 Lysfiske</h4>
            <p>
              Lysfiske egner seg godt til bestandsovervåking i mindre, grunne
              elver med lav vannføring, og vil bli benyttet i de vassdragene
              hvor forholdene ligger til rette for dette. Lysfiske er benyttet
              for å registrere mengde gytefisk i blant annet øvre deler av Surna
              (Johnsen mfl. 2011, Johnsen mfl. 2012b), Bævra (Johnsen mfl.
              2012a), Vigda, Skjenaldelva, Snilldalselva og Børsaelva (Solem
              mfl. 2016; 2019), og Søa og Åelva (Holthe mfl. 2016; 2019b).
            </p>
            <h5>2.2.1 Beskrivelse av metode</h5>
            <p>
              Lysfiske foregår etter mørkets frambrudd ved at minimum tre
              personer vader oppover elvestrengen og søker systematisk etter
              gytefisk ved hjelp av hodelykter og håndholdte, lyssterke lykter.
              En av tellerne har ansvar for å notere ned alle registreringer av
              gytefisk. Observert gytefisk blir lettest paralysert ved å
              konsentrere lys mot fiskens hode. I noen tilfeller vil det være
              aktuelt å fange flest mulig av observerte gytefisk, mens i andre
              tilfeller vil bare et utvalg av observerte gytefisk bli fanget for
              prøvetaking. Ved registrering uten fanging av fisk vil det bare
              bli notert ned gytefisk som blir passert av tellerrekka. Et større
              eller mindre utvalg gytefisk blir fanget i store håver. I
              forbindelse med prøvetaking oppbevares fiskene i håv eller
              bærebag, mens det blir gjennomført artsbestemmelse,
              kjønnsbestemmelse, lengdemåling og skjellprøvetaking. Etter
              prøvetaking blir all antatt villaks og sjøørret gjenutsatt i elva
              på samme sted som de ble fanget. En kan ved lystelling få meget
              høy oppløselig på hvor i vassdraget fisken befinner seg ved
              stedfesting ved hjelp av GPS. Metoden er nærmere beskrevet i
              Johnsen mfl. (2011) og Næsje mfl. (2013).
            </p>
            <h5>2.2.2 Presisjon på estimering av antall</h5>
            <p>
              Det foreligger ingen estimater hvor stor andel av gytefisken man
              klarer å observere på en gitt elvestrekning under lysfiske. Basert
              på erfaring er dyp, elvas bredde og vertikal sikt ned i vannet de
              tre viktigste parameterne som påvirker observasjonssannsynlighet
              gitt at lysfisket legges så tett inntil fiskens gyteperiode som
              mulig. I så måte vil nøyaktigheten kunne variere med elvas
              topografi. Gitt at forannevnte kriterier optimaliseres antas
              nøyaktigheten på lysfisket å være svært god. Lysfiske er velegnet
              i mindre vassdrag eller vassdragsavsnitt som kan vades over hele
              bredden og der drivtellinger ikke lar seg gjennomføre på en god
              måte.
            </p>
            <h4>2.3 Videoovervåkning</h4>
            <p>
              Videoovervåkning kan gi mye informasjon om laksebestandene i et
              vassdrag. Tilsvarende de andre metodene kan videotelling under
              egnede forhold gi informasjon av antall fisk som går opp i elvene.
              En av fordelene med video er at en kan estimere oppgang av fisk
              forbi tellepunktet før eventuell fangst, samt tidspunkt for
              oppvandring. En annen fordel med videoovervåkning er at hver fisk
              kan studeres på stillbilder, og en har derfor mere tid til å
              vurdere art, kjønn, størrelse og eventuelt oppdrettskarakterer på
              fiskene en har bilde av, forutsatt at siktforholdene og
              bildekvaliteten er tilstrekkelig god. Gjennomgang av video fra
              slik overvåkning og er ofte ressurskrevende, både med tanke på
              tidsbruk og kostnad, da det er en forutsetning at erfarent
              personell analyserer videomaterialet.
            </p>
            <h5>2.3.1 Beskrivelse av metode</h5>
            <p>
              Prinsippet for videoovervåking av oppvandrende laksefisk baseres
              på at fisk som passerer et punkt filmes enten gjennom et
              kontinuerlig opptak eller av en opptakssekvens som utløses av en
              sensor (mekanisk eller optisk). Valg av type overvåkingssystem vil
              derfor måtte tilpasses de ulike overvåkingslokalitetene, og kan
              grovt sett deles i 1) overvåking i fisketrapper og 2) overvåking
              av hele elvetverrsnitt.
            </p>
            <p>
              Det benyttes kamera som leverer SD-video (standard PAL oppløsning,
              720x576 pixler) som gir en reell oppløsning på over 550 TV-linjer.
              Kameraene har et synsfelt under vann på ca. 70 grader. For å sikre
              tilfredsstillende opptak når det er mørkt, benyttes kunstig lys
              (monokromatisk lys NINA Rapport 1849 14 eller IR). Videosignalene
              fra kameraene lagres på en harddisk-videoopptaker i time-lapse
              modus. Systemet er oppsatt med doble opptaksenheter for å sikre
              opptakene dersom en videoopptaker svikter. Bilderaten som benyttes
              under kontinuerlige opptak varierer mellom to til fire bilder per
              sekund. I fisketrapper brukes i tillegg en sensor til å utløse en
              videosekvens med bilderate på 50 bilder per sekund når en fisk
              passerer.
            </p>
            <p>
              I fisketrapper benyttes som regel ett eller to kamera som er
              koblet til en sensor/utløser i en kalv som fisken må passere
              gjennom, og kameraet står normalt om lag 70 cm fra fisken. I åpne
              tverrsnitt benyttes det systemer med flere kameraer (fire, åtte
              eller 12 kameraer) avhengig av tverrsnittets bredde og
              siktforholdene i elva. Avstanden mellom videokamera i åpne
              tverrsnitt varierer fra 1,2 til 2,5 meter, og tilpasses slik at
              ett kamera ser det neste kameraet i rekken (Svenning mfl. 2015).
            </p>
            <h5>2.3.2 Presisjon på estimering av antall</h5>
            <p>
              Videoovervåkning er en mye brukt metode til overvåking av fisk i
              elver, men metoden er i liten grad validert. Orell mfl. (2011)
              observerte imidlertid i et videosystem nederst i elva samtlige av
              18 radiomerkede fisk som vandret ut av en liten og klar sideelv i
              Tanavassdraget. Ytterligere valideringstester med bruk av
              radiomerket fisk er så vidt oss kjent ikke publisert, men det
              antas er metoden er nøyaktig under gode forhold med god sikt og
              tilstrekkelig lys, og om et tilstrekkelig antall kamera benyttes.
              Det er behov for ytterligere validering av metoden, spesielt i
              tilfeller hvor kamera brukes til å telle et tverrsnitt av
              elvebunnen, samt i forhold til hvordan sikt og vannføring kan
              bidra til liknende utfordringer som for drivtelling og om fisk kan
              vandre opp og ned forbi tverrsnittet flere ganger.
            </p>
            <h4>2.4 Rømt oppdrettsfisk</h4>
            <p>
              Forekomst av rømt oppdrettsfisk vil også bli rapportert for elvene
              som inngår i overvåkningen. Rømt oppdrettslaks vil bli forsøkt
              skilt fra villaks ut fra morfologiske karakterer som kroppsfasong,
              pigmentering, finneslitasje og andre karakterer som er typiske for
              rømt oppdrettsfisk under drivtellinger, lysfiske og ved
              videoovervåkning. I mange tilfeller vil det likevel ikke være
              mulig å identifisere oppdrettslaks basert på utseende, spesielt
              for laks som har rømt tidlig i oppdrettssyklusen. Flergangsgytere
              har også ofte skader som kan forveksles med skader hos
              oppdrettslaks. Rømt oppdrettslaks har erfaringsmessig en annen
              atferd enn villaks, og som ofte bidrar til at oppdrettslaks
              skiller seg ut når den opptrer i større grupper med villaks. For
              eksempel opptrer oppdrettslaks mer urolig, og vil i mindre grad
              søke skjul enn villaks når den påtreffes i elven. Under
              drivtellinger får en imidlertid ikke alltid sett enkeltfisk lenge
              nok til å avgjøre om det er villaks eller oppdrettslaks. Ved
              usikkerhet om fisken er oppdrettsfisk eller villfisk, defineres
              den som villfisk. Andelen rømt oppdrettslaks som fremkommer ved
              gytefisktellingene vil derfor i mange tilfeller være underestimert
              i forhold til det faktiske innslaget av rømt oppdrettslaks i elva.
              Erfaringsmessig vil en sjelden feilbestemme villaks som rømt
              oppdrettslaks (Skoglund mfl. 2014).
            </p>
            <p>
              Det er gjennomført en metodetest for å validere presisjonen i å
              identifisere oppdrettslaks under drivtelling (Mahlum mfl. 2019,
              også beskrevet over). Denne viser at relativt god overenstemmelse
              mellom andel oppdrettslaks observert i notkast og drivtelling
              (Figur 2). Dette studiet ble derimot gjennomført av erfarne
              dykkere i middels til gode observasjonsforhold, og hvordan denne
              presisjonen varierer med erfaring og observasjonsforhold er derfor
              uvisst. I tillegg ble det i dette studiet rapportert data fra
              uttak av oppdrettsfisk ved bruk av harpun i en rekke vassdrag. Kun
              én av 182 individer identifiserte som oppdrettslaks ble feil
              klassifisert (dvs. én villaks ble klassifisert som oppdrettslaks).
              Dette viser at det generelt sett er lite sannsynlig å overestimere
              antall oppdrettslaks basert på feilklassifisering.
            </p>
            Et mindre, tilsvarende studie ble tidligere utført i tre elver i
            Troms/Finnmark, der all oppdrettslaks ble korrekt kategorisert i to
            elver, men presisjonen var lavere i den tredje elva (Svenning mfl.
            2015).

            <p>
              Det er også ukjent hvordan presisjonen på beregninger av innslag
              av rømt oppdrettsfisk varierer mellom ulike metoder. En av
              fordelene med lysfiske er at en i mange tilfeller kan komme
              tettere på fisken og kan observere den i lengre tid, samt at en
              kan ta skjellprøver for analyser av vekstmønster og genetisk
              opphav. Identifisering fra video er avhengige av bildekvalitet mm,
              men har en fordel ved at fiskene kan sjekkes om igjen, og at
              bestemmelsene dermed er repeterbare. Variasjonen i nøyaktighet
              mellom de ulike metodene understreker av det er behov for å
              fokusere på harmonisering, metodeoptimalisering og
              kvalitetssikring.
            </p>
            <p>
              Forekomst av rømt oppdrettslaks i elver registrert i
              drivtellinger, samt ved andre registreringsmetoder (skjellprøver
              fra sportsfiske, høstfiske og stamfiske) blir fulgt opp i et eget
              nasjonalt overvåkingsprogram (Glover mfl. 2019).
            </p>
            <h4>2.5 Tidspunkt for gjennomføring</h4>
            <p>
              Sannsynligheten for å få en god gytefisktelling vil avhenge av
              tidspunktet for gjennomføring. Dette synes å være spesielt viktig
              i vassdrag hvor fisk oppholder seg i innsjøer før og etter gyting.
              Erfaringsmessig er det viktig å gjennomføre tellingen etter
              innsiget av anadromfisk og før hovedgytingen er ferdig, slik at
              fisken ikke har sluppet seg ned i sjøen eller i innsjøer etter
              gyting. Ettersom sjøørreten vanligvis gyter tidligere enn laksen,
              kan det i noen tilfeller være vanskelig å få til drivtellinger som
              fanger opp begge artene. Dette betyr i praksis at det i noen
              vassdrag er vanskelig å gjennomføre en god gytefisktelling både
              for laks og sjøørret på samme tidspunkt. Erfaringsmessig bruker
              ofte sjøørreten å være på gyteområdene i en kortere periode enn
              laksen, og det er derfor ofte et kortere tidsvindu for å fange opp
              gytende sjøørret på tellinger. Tidspunktet for gjennomføring av
              tellinger er i stor grad styrt av vannførings- og siktforhold i
              elvene, og ofte er det kun noen få dager tilgjengelig med egnete
              forhold for å gjennomføre tellinger om høsten. I enkelte vassdrag
              er det også ulike forhold som pålegg om minstevannføring eller
              breavsmelting som setter begrensinger for hvor tidlig tellinger
              kan utføres. I noen tilfeller vil det derfor være nødvendig å
              prioritere tidspunkt for tellinger for å sikre best mulig data for
              en av artene, noe som i praksis tilsier at viktige
              sjøørretvassdrag bør prioriteres tidlig i sesongen.
            </p>
            <h3>2.6 Kvalitetssikring</h3>
            <h4>2.6.1 Generelt om kvalitetssikring</h4>
            <p>
              Kvaliteten på data fra både drivtellinger, lysfiske og
              videoovervåking vil variere som følge av en rekke forhold. For
              eksempel vil tellingene være sterkt avhengig av
              observasjonsforhold, som ofte vil variere betydelig mellom
              vassdrag og over tid, samtidig som det ofte vil være lettere å
              observere alle/en større del av gytebestanden i små elver med få
              skjuleplasser enn i store vassdrag med store høler og loner. For
              hver av tellingene er det foretatt en kvalitetsvurdering hvor
              kvaliteten blir vurdert på en skala fra 1-4, der 1 angir best
              kvalitet og 4 angir dårligst kvalitet. Kvalitetsvurderingene
              beskriver både fysiske forhold som sikt og observasjonsforhold og
              vassdragets kompleksitet, når tellingene er utført i forhold til
              gytetidspunkt til henholdsvis laks og sjøaure i vassdraget, samt
              en totalvurdering om hvor godt dataene beskriver
              bestandsstørrelse. Følgende kvalitetselementer blir vurdert:
            </p>
            <ul>
              <li>Sikt og observasjonsforhold:</li>
              <ul>
                <li>1: svært gode,</li>
                <li>2: gode,</li>
                <li>3: middels,</li>
                <li>4: dårlig</li>
              </ul>
              <p></p>
              <li>
                Utfordringer med å identifisere fisk som følge av store
                vannvolum (dype høler/loner) eller store fisketettheter:
              </li>
              <ul>
                <li>1: lite utfordrende,</li>
                <li>2: mindre utfordrende,</li>
                <li>3: stedvis utfordrende,</li>
                <li>4: svært utfordrende</li>
              </ul>
              <p></p>
              <li>Laks -Utførelse i forhold til gytetidspunkt:</li>
              <ul>
                <li>
                  1: innenfor gyteperioden og mesteparten av fisken er på
                  gyteområdene,
                </li>
                <li>
                  2: innenfor gyteperioden eller tidspunkt da mesteparten av
                  bestanden er på elva, men enkelte er utgytt eller står i
                  oppholdshøler,
                </li>
                <li>
                  3: noe før eller etter, deler av bestanden er utgytt eller og
                  kan ha forlatt elva eller ikke kommet enda,
                </li>
                <li>
                  4: tidlig/sent, sannsynlig at en betydelig andel av bestanden
                  ikke er på elva
                </li>
              </ul>
              <p></p>
              <li>
                Laks- Total kvalitetsvurdering for hvor godt egnet dataene er
                for å beskrive bestandsstørrelse:
              </li>
              <ul>
                <li>1-svært god,</li>
                <li>2-god,</li>
                <li>3-middels,</li>
                <li>4-dårlig</li>
              </ul>
              <p></p>
              <li>Sjøørret -Utførelse i forhold til gytetidspunkt:</li>
              <ul>
                <li>
                  1: innenfor gyteperioden og mesteparten av fisken er på
                  gyteområdene,
                </li>
                <li>
                  2: innenfor gyteperioden eller tidspunkt da mesteparten av
                  bestanden er på elva, men enkelte er utgytt eller står i
                  oppholdshøler,
                </li>
                <li>
                  3: noe før eller etter, deler av bestanden er utgytt eller og
                  kan forlatt elva eller ikke kommet enda,
                </li>
                <li>
                  4: tidlig/sent, sannsynlig at en betydelig andel av bestanden
                  ikke er på elva
                </li>
              </ul>
              <p></p>
              <li>
                Sjøørret - total kvalitetsvurdering for hvor godt egnet dataene
                er for å beskrive bestandsstørrelse:
              </li>
              <ul>
                <li>1-svært god,</li>
                <li>2-god,</li>
                <li>3-middels,</li>
              </ul>
            </ul>

            <p>
              I tillegg gis det en totalvurdering av antatt
              observasjonssannsynlighet for laks og sjøørret i prosent for hvert
              vassdrag. Samlet gir kvalitetsvurderingen et grunnlag for å
              vurdere usikkerheter i tellingene, og hvilke faktorer som kan
              bidra til å påvirke presisjonene i datagrunnlaget.
            </p>
            <p></p>
            <p></p>
          </b-tab>
          <b-tab to="/om" title="Om Prosjektet">
            <p>
              <!-- Litt info om løsningen -->
              På oppdrag fra Miljødirektoratet ble det i 2019 opprettet ett
              nasjonalt program for overvåkning av gytebestander av laks og
              sjøørret. Tidligere har bestandsovervåkningen vært fordelt på
              flere institusjoner som hver for seg har rapportert sine
              resultater til Miljødirektoratet og Vitenskapelig råd for
              lakseforvaltning (VRL). Opprettelsen av et eget program for
              bestandsovervåkning gjør at resultater fra bestandsovervåkning fra
              de ulike deler av landet nå vil bli samlet i en rapport. Som et
              hovedmål for programmet skal det leveres kvalitetssikrede data som
              vurderer gytebestandsstørrelsen i de vassdragene som inngår i
              programmet. Prosjektgruppa skal også jobbe for å gjennomføre
              harmonisering og optimalisering av metoder for overvåkning av
              gytebestander av anadrom laksefisk i Norge.
            </p>
            <p>
              Skandinavisk Naturovervåkning AS, Ferskvannsbiologen AS,
              Rådgivende biologer AS, Veterinærinstituttet og Naturtjenester i
              Nord, sammen med NORCE og NINA utfører undersøkelsene i elvene som
              inngår i overvåkingsprogrammet. Samarbeidskonstellasjonen sikrer
              et faglig grunnlag for å vurdere bestandsstatus hos laks og
              sjøørret i ulike vassdrag i Norge. Deltagerne i prosjektet har
              siden tidlig på 1990-tallet jobbet med kartlegging av
              gytebestander og bestandsovervåking over hele landet, og har
              derfor bred erfaring med de metoder for fisketelling som er
              skissert i denne rapporten, og utvikling av disse.
            </p>
            <p>
              Alle de deltakende institusjoner har bidratt i rapporteringen av
              de innhentede resultater. I tillegg til de elver som
              Miljødirektoratet har bedt om overvåkning i, er det hentet inn
              data på gytebestandstørrelser fra flere vassdrag der de ulike
              institusjonene har gjennomført overvåkning. I vassdrag der
              Miljødirektoratet ikke har finansiert tellingene, er det etter
              beste evne forsøkt å synliggjøre finansieringskilder under hvert
              vassdrags delrapport. I denne rapporten presenteres de ulike
              metoder som blir benyttet under gytebestandsovervåkning, samt
              detaljerte resultater fra de vassdrag der det er gjennomført
              undersøkelser.
            </p>
            <p>
              For vurderinger av hvert vassdrag se
              <a href="https://www.vitenskapsradet.no/VurderingAvEnkeltbestander">Vitenskapelig råd for
                lakseforvaltning</a>.
            </p>
            <!-- <p>
              Vi ønsker å takke Miljødirektoratet for oppdraget, og takker
              samtidig alle andre som har finansiert ulike prosjekter som har
              bidratt med data til denne rapporten. Vi ønsker også å takke alle
              som har deltatt i forbindelse med prosjektet.
            </p>
            <p>Trondheim, Juni 2020</p>
            <p>Espen Holthe, Helge Skoglund og Øyvind Solem</p> -->
            <div style="align:center; text-align:center; display: inline-block;width: 80%; padding: 10px;">
              <div class="row">
                <div class="col-sm mx-auto logo" style="text-align:center; padding: 10px;">
                  <a href="https://www.norceresearch.no"><img :src="images.logo_norce" alt="NORCE" width="120" /></a>
                </div>
                <div class="col-sm mx-auto"></div>
                <div class="col-sm mx-auto logo" style="text-align:center; padding: 10px;">
                  <a href="https://www.nina.no"><img :src="images.logo_nina" alt="NINA" href="http://www.nina.no/"
                      width="120" /></a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm mx-auto" style="text-align:center; padding: 10px;">
                  <a href="https://www.radgivende-biologer.no/"><img :src="images.logo_rb" alt="Rådgivende biologer AS"
                      width="120" /></a>
                </div>
                <div class="col-sm mx-auto logo" style="text-align:center; padding: 10px;">
                  <a href="https://www.vetinst.no/"><img :src="images.logo_vi" alt="Veterinærinstituttet"
                      height="50" /></a>
                </div>
                <div class="col-sm mx-auto logo" style="text-align:center; padding: 10px;">
                  <a href="https://ninord.no/"><img :src="images.logo_ninord" alt="Naturtjenester i Nord"
                      width="120" /></a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm mx-auto" style="text-align:center; padding: 10px;">
                  <a href="https://www.facebook.com/Naturovervaking/"><img :src="images.logo_sno"
                      alt="Skandinavisk Naturovervåkning AS" width="120" /></a>
                </div>
                <div class="col-sm mx-auto"></div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Registrering" disabled>
            <p>Endre telling</p>
            <div>
              <div class="row">
                <div class="col-md-6 mb-4">Fylke:</div>
                <div class="w-50">
                  <b-form-select v-model="selectedCounty" :options="counties"
                    @change="getWatercoursesByCounty"></b-form-select>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-4">Vassdragsnummer og vassdrag:</div>
                <div class="w-50">
                  <b-form-select v-model="selectedWatercourseCode" :options="watercourses" @change="
          getLastReport(selectedWatercourseCode),
          getSelectedWatercourse(selectedWatercourseCode),
          getWatercourseForMap(w.code),
          getReportList(w.code)
          "></b-form-select>
                </div>
              </div>

              <div v-if="selectedReport">
                <BTabs content-class="mt-3">
                  <b-tab title="Generelt om elva og tellingen" active>
                    <!-- <p>I"m the first tab</p> -->

                    <!-- Start generelt om tellingen -->
                    <div class="container border p-4 test">
                      <h4>Generelt om elva og tellingen</h4>

                      <div class="row">
                        <div class="col-md-6 mb-4">Undersøkelsestidspunkt:</div>
                        <div class="w-50">
                          <b-form-datepicker id="dato"></b-form-datepicker>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Utførende institusjon:</div>
                        <div class="w-50">
                          <b-form-input v-model="selectedReport.excecutiveInstitution" placeholder="Fritekst">
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Metode:</div>
                        <div class="w-50">
                          <b-form-input v-model="selectedReport.method" placeholder="Fritekst">
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Innsjøer i anadrom del av vassdraget:
                        </div>
                        <div class="w-50">
                          <b-form-input v-model="selectedReport.lakeAnadrom" placeholder="Fritekst">
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Prosent av anadrom strekning undersøkt:
                        </div>
                        <div class="w-50">
                          <b-form-input v-model.number="selectedReport.percentAnadrom" placeholder="Fritekst">
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Elvestrekninger som inngår:
                        </div>
                        <div class="w-50">
                          <b-form-input v-model="selectedReport.riverStretch" placeholder="Fritekst">
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Vanntemperatur (C):</div>
                        <div class="w-50">
                          <b-form-input v-model="selectedReport.waterTemperature" placeholder="Fritekst">
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Vannføring (m3/s):</div>
                        <div class="w-50">
                          <b-form-input v-model="selectedReport.waterFlow" placeholder="Fritekst"></b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Effektiv sikt (meter):</div>
                        <div class="w-50">
                          <b-form-input v-model.number="selectedReport.visibility"
                            placeholder="Fritekst"></b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Kort feltrapport:</div>
                        <div class="w-50">
                          <b-form-textarea id="textarea" v-model="selectedReport.fieldReportText"
                            placeholder="Skriv noe her..." rows="4" max-rows="10"></b-form-textarea>
                        </div>
                      </div>
                    </div>
                    <!-- Slutt generelt om tellingen -->
                  </b-tab>
                  <b-tab title="Kvalitetsparametre">
                    <!-- <p>I"m the second tab</p> -->
                    <!-- Start kvalitetsparametre -->
                    <div class="container p-4">
                      <h4>Kvalitetsparametre</h4>
                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Sikt og observasjonsforhold:
                        </div>
                        <div class="w-50">
                          <b-form-select v-model="selectedReport.observationConditionsId" :options="siktOgObsforhold">
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Utfordringer med å identifisere fisk som følge av
                          store vannvolum (dype høler/loner) eller store
                          fisketettheter:
                        </div>
                        <div class="w-50">
                          <b-form-select v-model="selectedReport.challengesId" :options="utfordringer">
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Kvalitetsvurdering av hvor stor andel av gytebestanden
                          som trolig ble observert (i prosent) Laks:
                        </div>
                        <div class="w-50">
                          <b-form-input v-model.number="selectedReport.qP_SalmonQualityAssessmentPercent
          " type="number" placeholder="Fritekst (tall)">
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Kvalitetsvurdering av hvor stor andel av gytebestanden
                          som trolig ble observert (i prosent) SØ:
                        </div>
                        <div class="w-50">
                          <b-form-input v-model.number="selectedReport.qP_BrownTroutQualityAssessmentPercent
          " type="number" placeholder="Fritekst (tall)">
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Utførelse i forhold til gytetidspunkt Laks:
                        </div>
                        <div>
                          <b-form-select v-model="selectedReport.salmonExecutionTimeId" :options="utforelse">
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Utførelse i forhold til gytetidspunkt SØ
                        </div>
                        <div>
                          <b-form-select v-model="selectedReport.brownTroutExecutionTimeId" :options="utforelse">
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Laks - Total kvalitetsvurdering for hvor godt egnet
                          dataene er for å beskrive bestandsstørrelse:
                        </div>
                        <div class="w-50">
                          <b-form-select v-model="selectedReport.salmonQualityAssessmentId"
                            :options="kvalitetsvudering">
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Sjøørret - Total kvalitetsvurdering for hvor godt
                          egnet dataene er for å beskrive bestandsstørrelse:
                        </div>
                        <div class="w-50">
                          <b-form-select v-model="selectedReport.brownTroutQualityAssessmentId
          " :options="kvalitetsvudering">
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Begrunnelse for kvalitetsvudering:
                        </div>
                        <div class="w-50">
                          <b-form-textarea id="textarea" v-model="selectedReport.qP_QualityAssessmentRationale
          " placeholder="Skriv noe her..." rows="4" max-rows="10">
                          </b-form-textarea>
                        </div>
                      </div>
                    </div>
                    <!-- Slutt kvalitetsparametre -->
                  </b-tab>
                  <b-tab title="Telledata">
                    <!-- <p>I"m the third tab</p> -->
                    <!-- Start telledata -->
                    <div class="container  p-4">
                      <h4>Telledata</h4>

                      <table class="table table-bordered">
                        <thead>
                          <tr class="bg-default">
                            <th colspan="6">LAKS</th>
                            <th colspan="6">SJØØRRET</th>
                            <th colspan="2">SJØRØYE</th>
                          </tr>
                          <tr class="bg-default" style="font-size:10px">
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Små (1-3 kg)</th>
                            <th scope="col">Mellom (3-7 kg)</th>
                            <th scope="col">Store (> 7 kg</th>
                            <th scope="col">Totalt</th>
                            <th scope="col">Umodne</th>
                            <th scope="col">&lt; 1 kg</th>
                            <th scope="col">1-3 kg</th>
                            <th scope="col">3-5 kg</th>
                            <th scope="col">&gt;5 kg</th>
                            <th scope="col">Totalt</th>
                            <th scope="col">&lt; 0,5 kg</th>
                            <th scope="col">&gt; 0,5 kg</th>
                          </tr>
                          <tr>
                            <td>Villfisk</td>
                            <td></td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_WildSalmonSmallFemale
          " type="number"></b-form-input>
                              <b-form-input v-model.number="selectedReport.cD_WildSalmonSmallMale
          " type="number"></b-form-input>
                              <b-form-input v-model.number="selectedReport.cD_WildSalmonSmallUnknown
          " type="number"></b-form-input>
                              <!-- {{ selectedReport.cD_WildSalmonSmallFemale }} |
                    {{ selectedReport.cD_WildSalmonSmallMale }} |
                    {{ selectedReport.cD_WildSalmonSmallUnknown }} | -->
                              <!-- {{ selectedReport.cD_WildSalmonSmall }} -->
                            </td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_WildSalmonMediumFemale
          " type="number"></b-form-input>
                              <b-form-input v-model.number="selectedReport.cD_WildSalmonMediumMale
          " type="number"></b-form-input>
                              <b-form-input v-model.number="selectedReport.cD_WildSalmonMediumUnknown
          " type="number"></b-form-input>
                              <!-- {{ selectedReport.cD_WildSalmonMediumFemale }} |
                    {{ selectedReport.cD_WildSalmonMediumMale }} |
                    {{ selectedReport.cD_WildSalmonMediumUnknown }} | -->
                              <!-- {{ wildSalmonMediumAll }} -->
                            </td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_WildSalmonLargeFemale
          " type="number"></b-form-input>
                              <b-form-input v-model.number="selectedReport.cD_WildSalmonLargeMale
          " type="number"></b-form-input>
                              <b-form-input v-model.number="selectedReport.cD_WildSalmonLargeUnknown
          " type="number"></b-form-input>
                              <!-- {{ selectedReport.cD_WildSalmonLargeFemale }} |
                    {{ selectedReport.cD_WildSalmonLargeMale }} |
                    {{ selectedReport.cD_WildSalmonLargeUnknown }} | -->
                              <!-- {{ wildSalmonLargeAll }} -->
                            </td>
                            <td>
                              {{ sumWildSalmon }}
                            </td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_BrownTroutImmature
          " type="number"></b-form-input>
                            </td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_BrownTrout_0_1
          " type="number"></b-form-input>
                            </td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_BrownTrout_1_3
          " type="number"></b-form-input>
                            </td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_BrownTrout_3_5
          " type="number"></b-form-input>
                            </td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_BrownTrout_5"
                                type="number"></b-form-input>
                            </td>
                            <td>
                              {{ sumBrownTrout }}
                            </td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_ArcticChar_0_05
          " type="number"></b-form-input>
                            </td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_ArcticChar_05"
                                type="number"></b-form-input>
                            </td>
                          </tr>
                          <tr>
                            <td>Oppdrett</td>
                            <td></td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_CultivatedSalmonSmall
          " type="number"></b-form-input>
                              <!-- {{ selectedReport.cD_CultivatedSalmonSmall }} -->
                            </td>

                            <td>
                              <b-form-input v-model.number="selectedReport.cD_CultivatedSalmonMediumFemale
          " type="number"></b-form-input>
                              <b-form-input v-model.number="selectedReport.cD_CultivatedSalmonMediumMale
          " type="number"></b-form-input>
                              <b-form-input v-model.number="selectedReport.cD_CultivatedSalmonMediumUnknown
          " type="number"></b-form-input>
                              <!-- {{ selectedReport.cD_CultivatedSalmonMediumFemale }} |
                    {{ selectedReport.cD_CultivatedSalmonMediumMale }} |
                    {{ selectedReport.cD_CultivatedSalmonMediumUnknown }} | -->
                              <!-- {{ cultivatedSalmonMediumAll }} -->
                            </td>
                            <td>
                              <b-form-input v-model.number="selectedReport.cD_CultivatedSalmonLargeFemale
          " type="number"></b-form-input>
                              <b-form-input v-model.number="selectedReport.cD_CultivatedSalmonLargeMale
          " type="number"></b-form-input>
                              <b-form-input v-model.number="selectedReport.cD_CultivatedSalmonLargeUnknown
          " type="number"></b-form-input>
                              <!-- {{ selectedReport.cD_CultivatedSalmonLargeFemale }} |
                    {{ selectedReport.cD_CultivatedSalmonLargeMale }} |
                    {{ selectedReport.cD_CultivatedSalmonLargeUnknown }} | -->
                              <!-- {{ cultivatedSalmonLargeAll }} -->
                            </td>
                            <td>
                              {{ sumCultivated }}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colspan="13"></td>
                          </tr>
                          <tr>
                            <td colspan="4">Observert kultivert laks (%)</td>
                            <td colspan="9">
                              <b-form-input v-model.number="selectedReport.cD_CultivatedSalmonPercent
          " type="number" step=".01"></b-form-input>
                              <!-- {{ selectedReport.cD_CultivatedSalmonPercent }} -->
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">Observert kultivert laks (n)</td>
                            <td colspan="9">
                              <b-form-input v-model.number="selectedReport.cD_CultivatedSalmon
          " type="number"></b-form-input>
                              <!-- {{ selectedReport.cD_CultivatedSalmon }} -->
                            </td>
                          </tr>
                        </thead>
                      </table>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Rådata fra tellingen sonevis:
                        </div>
                        <div class="w-50">
                          <b-form-file v-model="file" :state="Boolean(file)"
                            placeholder="Velg en fil, eller slipp den her" drop-placeholder="Drop file here...">
                          </b-form-file>
                          <div class="mt-3">
                            Valgt fil: {{ file ? file.name : "" }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Slutt telledata -->
                  </b-tab>
                  <b-tab title="Kart og soneinndeling">
                    <p>
                      Her kommer kart med soneinndeling, og tabell med sonene.
                    </p>
                    <div class="row">
                      <div class="col-md-6 mb-4">Opplasting av bilde:</div>
                      <div class="w-50">
                        <b-form-file v-model="file" :state="Boolean(file)"
                          placeholder="Velg en fil, eller slipp den her" drop-placeholder="Drop file here...">
                        </b-form-file>
                      </div>
                    </div>
                  </b-tab>
                </BTabs>
                <div class="row p-4">
                  <b-button @click="saveReport()" variant="success">Lagre telling</b-button>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Import" disabled>
            <p><!-- Last opp rådata fra excel-dokument --></p>
            <div class="row">
              <div class="col-md-6 mb-4">
                Import fra Excel-dokument.
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-4">Last opp data fra Excel-dokument:</div>
              <div class="w-50">
                <b-form-file v-model="file" :state="Boolean(file)" placeholder="Velg en fil, eller slipp den her"
                  drop-placeholder="Drop file here...">
                </b-form-file>
              </div>
            </div>
          </b-tab>
        </BTabs>
      </div>
    </div>
    <div>
      <!-- Image and text -->
    </div>
  </b-container>
  <!-- </Layout> -->
</template>

<script>

import Axios from "axios";
import Vue from "vue";
// import Layout from 'router/layouts/main.vue'
import { BESTANDSOVERVAKNING_CONFIG } from "@/../config.js";
// import appConfig from "@src/app.config"
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Icon } from "leaflet";
// import { router } from "@/router/index.js";

const moment = require("moment");

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import {
  LMap,
  LTileLayer,
  LCircleMarker,
  LControlLayers,
  LTooltip,
  LWMSTileLayer
} from "vue2-leaflet";

import {
  BTabs,
  BTab,
  BFormInput,
  BFormSelect,
  BFormDatepicker,
  BFormTextarea,
  BFormFile,
  BButton
} from "bootstrap-vue";

import * as bulmaToast from "bulma-toast";

export default {
  page: {
    title: "Home",
    meta: [{ name: "description", content: "Laksetelling" }]
  },
  components: {
    BTabs: BTabs,
    "b-tab": BTab,
    "b-form-input": BFormInput,
    "b-form-select": BFormSelect,
    "b-form-datepicker": BFormDatepicker,
    "b-form-textarea": BFormTextarea,
    "b-form-file": BFormFile,
    "b-button": BButton,
    "l-map": LMap,
    "l-tile-layer": LTileLayer,
    "l-circle-marker": LCircleMarker,
    "l-control-layers": LControlLayers,
    "l-tooltip": LTooltip,
    "l-wms-tile-layer": LWMSTileLayer
  },
  data() {
    return {
      images: {
        logo_sno: require("../assets/SNO_logo (002).jpg"),
        logo_rb: require("../assets/RB_logo.png"),
        logo_vi: require("../assets/VI_logo.png"),
        logo_ninord: require("../assets/NINORDlogo_2 copy.png"),
        logo_norce: require("../assets/NORCE logo.png"),
        logo_nina: require("../assets/NINA_logo.png")
      },
      counties: null,
      countiesWithWatercourses: null,
      watercourses: null,
      watercoursesForMap: null,
      watercourseForMap: null,

      selectedCounty: null,
      selectedWatercourseCode: null,
      selectedWatercourse: null,
      selectedReport: null,
      reportList: null,
      selectedYear: 2020,
      tabIndex: 1,
      watercourseMap: {},

      menu: [
        {
          header: true,
          title: "Main Navigation",
          hiddenOnCollapse: true
        },
        {
          href: "/",
          title: "Dashboard",
          icon: "fa fa-user"
        },
        {
          href: "/charts",
          title: "Charts",
          icon: "fa fa-chart-area",
          child: [
            {
              href: "/charts/sublink",
              title: "Sub Link"
            }
          ]
        }
      ],

      readonly: "",

      center: [65.8369, 13.1934],
      zoom: 5,
      baseUrl: "https://opencache.statkart.no/gatekeeper/gk/gk.open",

      tileProviders: [
        {
          name: "OpenStreetMap",
          visible: true,
          center: [62.6752, 8.5633],
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        },
        {
          name: "OpenTopoMap",
          visible: false,
          center: [62.6752, 8.5633],
          url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
        }
      ],

      layers: [
        {
          name: "Topo4",
          visible: true,
          format: "image/png",
          layers: "topo4",
          transparent: true,
          version: "1.0",
          attribution: "Kartverket"
        }
      ],

      circle: {
        center: [62.676407, 8.550287],
        radius: 10,
        color: "blue"
      },

      searchWatercourse: null,

      selectedUtovendeInstitusjon: null,
      selectedMetode: null,
      selectedSiktOgObsforhold: null,
      selectedUtfordringer: null,
      selectedUtforelseLaks: null,
      selectedUtforelseSO: null,
      selectedKvalitetsvurderingLaks: null,
      selectedKvalitetsvurderingSO: null,
      file: null,
      showImage: false,

      utovendeInstitusjon: [
        { value: null, text: "Velg utøvende institusjon" },
        { value: 0, text: "NINA" },
        { value: 1, text: "NORCE" },
        { value: 2, text: "Skandinavisk naturovervåkning" },
        { value: 3, text: "Rådgivende biologer" },
        { value: 4, text: "Ferskvannsbiologen" },
        { value: 5, text: "Veterinærinstituttet" },
        { value: 6, text: "Andre" }
      ],
      metode: [
        { value: null, text: "Velg metode" },
        { value: 1, text: "Drivtelling" },
        { value: 2, text: "Lysfiske" },
        { value: 3, text: "Drivtelling og lysfiske" },
        { value: 4, text: "Video" }
      ],
      siktOgObsforhold: [
        { value: null, text: "Velg" },
        { value: 1, text: "Svært gode" },
        { value: 2, text: "Gode" },
        { value: 3, text: "Middels" },
        { value: 4, text: "Dårlige" }
      ],
      utfordringer: [
        { value: null, text: "Velg" },
        { value: 1, text: "Lite utfordrende" },
        { value: 2, text: "Mindre utfordrende" },
        { value: 3, text: "Stedvis utfordrende" },
        { value: 4, text: "Svært utfordrende" }
      ],
      utforelse: [
        { value: null, text: "Velg" },
        {
          value: 1,
          text:
            "Innenfor gyteperioden og mesteparten av fisken er på gyteområdene"
        },
        {
          value: 2,
          text:
            "Innenfor gyteperioden eller tidspunkt da mesteparten av bestanden er på elva, men enkelte er utgytt eller står i oppholdshøler"
        },
        {
          value: 3,
          text:
            "Noe før eller etter, deler av bestanden er utgytt eller og kan forlatt elva eller ikke kommet enda"
        },
        {
          value: 4,
          text: "Tidlig/sent og mulig at del av bestanden ikke er på elva"
        }
      ],
      kvalitetsvudering: [
        { value: null, text: "Velg" },
        { value: 1, text: "Svært god" },
        { value: 2, text: "God" },
        { value: 3, text: "Middels" },
        { value: 4, text: "Dårlig" }
      ],
      mainImageUrl: null,
      salmonMainImageError: false,
      salmonMainVariantError: false,
      salmonVariantError: false,
      troutMainImageError: false,
      troutMainVariantError: false,
      troutVariantError: false,
      imageErrors: [false, false, false, false, false, false],
      modalVisible: false,
    };
    
  },

  computed: {
    wildSalmonSmallAll: function () {
      return (
        this.selectedReport.cD_WildSalmonSmallFemale +
        this.selectedReport.cD_WildSalmonSmallMale +
        this.selectedReport.cD_WildSalmonSmallUnknown
      );
    },
    wildSalmonMediumAll: function () {
      return (
        this.selectedReport.cD_WildSalmonMediumFemale +
        this.selectedReport.cD_WildSalmonMediumMale +
        this.selectedReport.cD_WildSalmonMediumUnknown
      );
    },
    wildSalmonLargeAll: function () {
      return (
        this.selectedReport.cD_WildSalmonLargeFemale +
        this.selectedReport.cD_WildSalmonLargeMale +
        this.selectedReport.cD_WildSalmonLargeUnknown
      );
    },
    cultivatedSalmonMediumAll: function () {
      return (
        this.selectedReport.cD_CultivatedSalmonMediumFemale +
        this.selectedReport.cD_CultivatedSalmonMediumMale +
        this.selectedReport.cD_CultivatedSalmonMediumUnknown
      );
    },
    cultivatedSalmonLargeAll: function () {
      return (
        this.selectedReport.cD_CultivatedSalmonLargeFemale +
        this.selectedReport.cD_CultivatedSalmonLargeMale +
        this.selectedReport.cD_CultivatedSalmonLargeUnknown
      );
    },
    sumWildSalmon: function () {
      return (
        this.selectedReport.cD_WildSalmonSmallFemale +
        this.selectedReport.cD_WildSalmonSmallMale +
        this.selectedReport.cD_WildSalmonSmallUnknown +
        this.selectedReport.cD_WildSalmonMediumFemale +
        this.selectedReport.cD_WildSalmonMediumMale +
        this.selectedReport.cD_WildSalmonMediumUnknown +
        this.selectedReport.cD_WildSalmonLargeFemale +
        this.selectedReport.cD_WildSalmonLargeMale +
        this.selectedReport.cD_WildSalmonLargeUnknown
      );
    },
    sumCultivated: function () {
      return (
        this.selectedReport.cD_CultivatedSalmonSmall +
        this.selectedReport.cD_CultivatedSalmonMediumFemale +
        this.selectedReport.cD_CultivatedSalmonMediumMale +
        this.selectedReport.cD_CultivatedSalmonMediumUnknown +
        this.selectedReport.cD_CultivatedSalmonLargeFemale +
        this.selectedReport.cD_CultivatedSalmonLargeMale +
        this.selectedReport.cD_CultivatedSalmonLargeUnknown
      );
    },
    sumBrownTrout: function () {
      return (
        this.selectedReport.cD_BrownTrout_0_1 +
        this.selectedReport.cD_BrownTrout_1_3 +
        this.selectedReport.cD_BrownTrout_3_5 +
        this.selectedReport.cD_BrownTrout_5
      );
    }
  },

  mounted() {
    const self = this;

    this.$nextTick(() => { });
    Axios.get(
      `${BESTANDSOVERVAKNING_CONFIG.API_URL}` + "/watercourse/countiesdd"
    ).then(response => (self.counties = response.data));
    Axios.get(
      `${BESTANDSOVERVAKNING_CONFIG.API_URL}` + "/watercourse/countiesw"
    ).then(response => (self.countiesWithWatercourses = response.data));

    Axios.get(
      `${BESTANDSOVERVAKNING_CONFIG.API_URL}` + "/watercourse/watercoursesdd"
    ).then(response => (self.watercourses = response.data));
    Axios.get(
      `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
      "/watercourse/watercoursesForMap"
    ).then(res => {
      self.watercoursesForMap = res.data;
      self.getMap();
    });

    this.watercourseMap = L.map(this.$refs["mapElement"]);
    this.watercourseMap.dragging.disable();
    this.watercourseMap.scrollWheelZoom.disable();

    this.watercourseMap.setView([62.67757925, 8.550245158], 8);

    var topo4 = new L.TileLayer.WMS(
      "https://opencache.statkart.no/gatekeeper/gk/gk.open",
      {
        maxZoom: 20,
        layers: "topo4",
        format: "image/png",
        transparent: true,
        version: "1.0",
        attribution: "Kartverket"
      }
    );
    topo4.addTo(this.watercourseMap);

    this.markerLayerGroup = L.layerGroup().addTo(this.watercourseMap);

    // ---
    // this.getLastReportInitial("030.2Z");
    // this.getSelectedWatercourse("030.2Z");
    // this.getWatercourseForMap("030.2Z");
    // this.getReportList("030.2Z");

    if (this.$route.params.id != undefined) {
      this.getLastReport(this.$route.params.id);
      this.getSelectedWatercourse(this.$route.params.id);
      this.getWatercourseForMap(this.$route.params.id);
      this.getReportList(this.$route.params.id);
    }

    this.checkAndLoadImages();
  },

  methods: {
    // onTabClick(tab) {
    //   this.$router.push(tab.path);
    // },

    getSelectedReport(event) {
      var reportId = event.target.value;
      this.getReportById(reportId);
    },
    getCountyId(index) {
      return "accordion-" + index;
    },
    doSomethingOnReady() {
      this.map = this.$refs.myMap.mapObject;
    },

    getMap() { },

    getWatercoursesByCounty(countyId) {
      let self = this;
      var url =
        `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
        "/watercourse/watercourses/" +
        countyId;

      Axios.get(url).then(response => (self.watercourses = response.data));
    },

    getLastReport(watercourseCode) {
      const self = this;
      if (watercourseCode != undefined) {
        var url =
          `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
          "/watercourse/lastReport/" +
          watercourseCode;

        Axios.get(url).then(res => {
          self.selectedReport = res.data;
          self.tabIndex = 1;
          self.selectedCounty = res.data.watercourseCountyNumber;
          self.selectedWatercourseCode = watercourseCode;
          self.mapReady();
          // alert("Test:" + this.$route.params.id);
          if (self.$route.params.id !== watercourseCode) {
            self.$router.push({
              name: "Home",
              params: { id: watercourseCode }
            });
          }
        });
      }
    },

    getLastReportInitial(watercourseCode) {
      const self = this;
      if (watercourseCode != undefined) {
        var url =
          `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
          "/watercourse/lastReport/" +
          watercourseCode;

        Axios.get(url).then(res => {
          self.selectedReport = res.data;
          self.selectedCounty = res.data.watercourseCountyNumber;
          self.selectedWatercourseCode = watercourseCode;
          self.mapReady();
        });
      }
    },

    getReportById(id) {
      const self = this;
      var url =
        `${BESTANDSOVERVAKNING_CONFIG.API_URL}` + "/watercourse/report/" + id;

      Axios.get(url).then(res => {
        self.selectedReport = res.data;
        self.tabIndex = 1;
        self.selectedCounty = res.data.watercourseCountyNumber;
      });
    },

    getReportList(code) {
      let self = this;
      var url =
        `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
        "/watercourse/reports/" +
        code;

      Axios.get(url).then(
        response => ((self.reportList = response.data), self.mapReady())
      );
    },

    getWatercourseForMap(code) {
      let self = this;
      var url =
        `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
        "/watercourse/watercourseForMap/" +
        code;

      Axios.get(url).then(
        response => ((self.watercourseForMap = response.data), self.mapReady())
      );
    },

    searchForWatercourse() {
      let self = this;
      var watercourseCode = this.searchWatercourse.substring(
        0,
        self.searchWatercourse.indexOf(" ")
      );

      var watercourse = self.watercoursesForMap.filter(obj => {
        return obj.code === watercourseCode;
      });

      var code = watercourse[0].code;

      self.getLastReport(code);
      self.getSelectedWatercourse(code);
      self.getReportList(code);
      self.getWatercourseForMap(code);
    },

    getSelectedWatercourse(watercourseCode) {
      let self = this;
      if (watercourseCode != undefined) {
        var url =
          `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
          "/watercourse/watercourse/" +
          watercourseCode;

        Axios.get(url).then(response => {
          self.selectedWatercourse = response.data;
          self.mapReady();
          // self.$router.push({ path: "/vassdrag/" + watercourseCode });
        });
      }
    },

    saveReport(report) {
      report = this.selectedReport;
      var url = `${BESTANDSOVERVAKNING_CONFIG.API_URL}` + "/watercourse/save";

      Axios.post(url, report).then(response => {
        alert(JSON.stringify(response));
        bulmaToast.toast({ message: "Lagret!" });
      });
    },

    formattedDate: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },

    yearOnly: function (date) {
      return moment(date).format("YYYY");
    },

    mapReady() {
      var self = this;
      if (
        self.watercourseForMap != undefined &&
        self.watercourseForMap.latitude != undefined
      ) {
        self.markerLayerGroup.clearLayers();

        L.marker([
          self.watercourseForMap.latitude,
          self.watercourseForMap.longitude
        ]).addTo(self.markerLayerGroup);

        Vue.nextTick().then(function () {
          self.watercourseMap.invalidateSize(true);
          self.watercourseMap.flyTo(
            [self.watercourseForMap.latitude, self.watercourseForMap.longitude],
            12
          );
        });
      }
    },

    mapReadyMainMap() {
      var self = this;
      Vue.nextTick().then(function () {
        self.map.invalidateSize(true);
        self.map.flyTo([65.8369, 13.1934], 5);
      });
    },

    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    // Function to check if an image exists
    imageExists(url) {
    return Axios.head(url)
      .then(response => response.status === 200)
      .catch(() => false);
    },

    async checkAndLoadImages() {
      const urls = [
        this.getSalmonMainImageUrl(),
        this.getSalmonMainImageUrl(true),
        this.getSalmonVariantImageUrl(),
        this.getTroutMainImageUrl(),
        this.getTroutMainImageUrl(true),
        this.getTroutVariantImageUrl()
      ];

      this.imageErrors = Array(urls.length).fill(false); // Reset errors before checking

      urls.forEach((url, index) => {
        this.imageExists(url).then(exists => {
          this.$set(this.imageErrors, index, !exists);
        });
      });
   },

    

    // Construct image URL with specific naming for fish types
    constructImageUrl(folder, code, variant = '') {
      // const baseUrl =  `${BESTANDSOVERVAKNING_CONFIG.BASE_URL}`; // Ensure there is a fallback if BASE_URL is not defined
      const baseUrl = process.env.BASE_URL || ''; // Ensure there is a fallback if BASE_URL is not defined
      const fishName = folder === 'salmon' ? ' Laks' : ' Sjøørret'; // Adjust based on folder
      code = encodeURIComponent(code);
      variant = encodeURIComponent(variant);
      
      // Construct the URL string using encoded values and checking if baseUrl ends with a slash
      const myUrl = `${baseUrl.endsWith('/') ? baseUrl : baseUrl + '/'}stats/${folder}/${code}${variant}${fishName}.png`;

      // Log the constructed URL to the console for debugging
      console.log(`Constructed URL: ${myUrl}`);
      
      // Return the constructed URL
      return myUrl;
    },

    getSalmonMainImageUrl(withVariant = false) {
      const code = this.$route.params.id;
      const variantSuffix = withVariant ? ' (a)' : '';
      return this.constructImageUrl('salmon', code, variantSuffix);
    },
    getSalmonVariantImageUrl() {
      const code = this.$route.params.id;
      return this.constructImageUrl('salmon', code, ' (b)');
    },
    getTroutMainImageUrl(withVariant = false) {
      const code = this.$route.params.id;
      const variantSuffix = withVariant ? ' (a)' : '';
      return this.constructImageUrl('trout', code, variantSuffix);
    },
    getTroutVariantImageUrl() {
      const code = this.$route.params.id;
      return this.constructImageUrl('trout', code, ' (b)');
    },

    // Determine correct image URL by checking availability
    async determineCorrectImageUrl(folder, code, variants) {
      for (let variant of variants) {
        const url = this.constructImageUrl(folder, code, variant);
        if (await this.imageExists(url)) {
          return url;
        }
      }
      return `${process.env.BASE_URL || ''}stats/default.png`; // Default image if none found
    },

    // Error handler for image loading
    imageLoadOnError(e, errorType) {
      const defaultImageUrl = `${process.env.BASE_URL || ''}stats/default.png`;
      e.target.src = defaultImageUrl;
      this[errorType] = true; // Set the error flag
    },

    openModal(imageUrl) {
      this.currentImageUrl = imageUrl;
      this.modalVisible = true;
    },

  },
  watch:{
    selectedWatercourseCode(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checkAndLoadImages();
      }
    }
  }
  // watch: {
  //   $route: function(to) {
  //     let tab = this.$refs.tabs.tabs.find(tab => tab.to === to.path);
  //     if (tab) this.$refs.tabs.setActiveTab(tab);
  //   }
  // },
  // beforeRouteUpdate(to, from, next) {
  //   let tab = this.$refs.tabs.tabs.find(tab => tab.to === to.path);
  //   if (tab) this.$refs.tabs.setActiveTab(tab);
  //   next();
  // }
};

</script>

<style>
.menu-content {
  display: inline-block;
  width: 25%;
  height: 100vh;
  background-color: #d5d5e3;
  height: 100vh;
  overflow-y: scroll;
}

.main-content {
  float: right;
  width: 75%;
  background-color: white;
  padding: 15px;
  overflow-y: scroll;
  height: 100vh;
}

@media only screen and (max-width: 768px) {
  .menu-content {
    display: none;
    width: 0%;
  }

  .main-content {
    width: 100%;
  }


}
</style>
